import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const FAQContent = ({ faqQuestion, faqAnswer }) => {
    return (
            <AccordionItem id="Faq">
                <AccordionItemHeading>
                    <AccordionItemButton>
                        {faqQuestion}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        {faqAnswer}
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
    );
}

class FAQ extends React.Component {
    submitHandler = (e) => {
        e.preventDefault();
        
    }
    render() {
        return (
            <React.Fragment>
                <div className="page-title-area item-bg2">
                    <div className="container">
                        <h1>FAQ</h1>
                        <span>Frequently asked Questions</span>
                    </div>
                </div>

                <section className="faq-area ptb-120">
                    <div className="container">
                        <div className="faq-accordion">
                            <Accordion>
                                <FAQContent faqQuestion="Who can join the Hackathon?" faqAnswer="Hackathon is open to anyone interested in coding - or similar roles like project management or design." />
                                <FAQContent faqQuestion="Why should I attend this event?" faqAnswer="The Hackathon is a great chance to network and meet like-minded people in the community. You will be able to meet with business owners and mentors to learn about their challenges and experiences first-hand. Perhaps most importantly, you can create something valuable and build a project with a purpose." />
                                <FAQContent faqQuestion="Does the Hackathon have a theme?" faqAnswer="Yes! The theme of the Hackathon is downtown revitalization with the goal of getting people to work, play and live downtown. This hackathon hopes to promote coding and software development as a rewarding career and present an opportunity to be part of a community." />
                                <FAQContent faqQuestion="How will things be judged?" faqAnswer="There will be specific judging criteria that the judges will use during the Hackathon. Please see the Judges section above." />
                                <FAQContent faqQuestion="Are projects decided in advance?" faqAnswer="Vignettes will be shown to the attendees about topics that they can create a project to solve. For example : security, green line, business, mobility, etc." />
                                <FAQContent faqQuestion="Will teams be automatically assigned?" faqAnswer="People can join the Hackathon with their own team or join a team on Friday night. This is a great opportunity to meet others in your community! Team names are welcome and encouraged!" />
                            </Accordion>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default FAQ;
