import React from 'react'

export default function SponsorItem({ imageName, imageSrc, siteHref }) {
  return (
    <div className='col-md-3'>
      <div className="partner-item">
        <a href={siteHref} target="_blank" rel='noopener noreferrer'>
          <img src={imageSrc} alt={imageName} />
          <img src={imageSrc} alt={imageName} />
        </a>
      </div>
    </div>
  )
}
