import React, { useState, useEffect, useCallback } from "react"

const CountdownTimer = ({ endTimeValue }) => {
    const [days, setDays] = useState('')
    const [hours, setHours] = useState('')
    const [minutes, setMinutes] = useState('')
    const [seconds, setSeconds] = useState('')

    const makeTimer = useCallback(() => {
        let endTime = new Date(endTimeValue);
        let endTimeParse = (Date.parse(endTime)) / 1000;
        let now = new Date();
        let nowParse = (Date.parse(now) / 1000);
        let timeLeft = endTimeParse - nowParse;
        let days = Math.floor(timeLeft / 86400);
        let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
        let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60);
        let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
setDays((days < "0" && "0") || days)
setHours((hours < "0" && "0") || hours)
setMinutes((minutes < "0" && "0") || minutes)
setSeconds((seconds < "0" && "0") || seconds)
    }, [endTimeValue])

    useEffect(() => {
        const interval = setInterval(() => {
            makeTimer();
        }, 1000);
        return () => clearInterval(interval);
    }, [makeTimer]);

    return (
        <div className="event-countdown countdown1">
            <div id="timer">
                <div>
                    <div id="days">{days} <span>Days</span></div>
                    <div id="hours">{hours} <span>Hours</span></div>
                    <div id="minutes">{minutes} <span>Minutes</span></div>
                    <div id="seconds">{seconds} <span>Seconds</span></div>
                </div>
            </div>
        </div>
    )
}

export default CountdownTimer;