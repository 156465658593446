import React, { useState } from "react"

function AccordionItem({ title, content }) {
  const [contentOpen, setContentOpen] = useState(false)

  function handleClick() {
    setContentOpen(!contentOpen)
  }

  return (
    <li className="accordion-item" onClick={handleClick}>
      {title}
      {contentOpen ? content : null}
    </li>

  )
}

export default AccordionItem