import React from 'react';
import lax from 'lax.js';

class About extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()

        document.addEventListener('scroll', function (x) {
            lax.update(window.scrollY)
        }, false)

        lax.update(window.scrollY)
    }

    render() {
        return (
            <section className="about-area ptb-120 bg-image">
                <div className="container">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-6">
                            <div className="about-content">
                                <span>Join The Event</span>
                                <h2>Be a Part of <b>Downtown</b> Revitalization</h2>
                                <p>Like all places, Alberta is not immune to the upheaval and changes faced by the
                                    global community over the past ten-plus years. From energy sector boom/bust cycles,
                                    to addressing existential challenges such as climate change, to global health
                                    emergencies, the province has seen rapid changes that have also led to new
                                    opportunities.</p>

                                <p>This hackathon hopes to promote coding and software development as a rewarding and
                                    attractive career, leveraging wider trends by offering curious, aspiring, or working
                                    coders of all abilities the chance to participate in a event to learn, network, and
                                    be part of a community.</p>

                                <a href="https://www.showpass.com/calgary-hackathon-2022"
                                   target="_blank"
                                   rel='noopener noreferrer' className="btn btn-secondary">Buy Ticket</a>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about-image">
                                <img src={require("../../assets/images/about1.jpg")} className="about-img1"
                                     alt="about"/>

                                <img src={require("../../assets/images/about2.jpg")} className="about-img2"
                                     alt="about"/>

                                <img src={require("../../assets/images/shapes/5.png")} className="shape-img"
                                     alt="about"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default About;