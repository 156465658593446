import React from "react"
import LaxDiv from '../Shared/LaxDiv'
import WinnersDetails from './WinnersDetails'

const Winners = () => {
  return (
    <section className="speakers-area ptb-120" id="speakers">
      <div className="container">
        <div className="section-title">
          <h2>Winners</h2>
          <div className="bar"></div>
          <LaxDiv text="Winners" dataPreset="driftLeft" />
        </div>
      </div>
      <div className="row gap-md-4 m-0 d-flex justify-content-center">
        <WinnersDetails />
      </div>
    </section>
  )
}

export default Winners
