import React from "react";

function Rules() {
  return (
    <section className="rules-area ptb-120" id="rules">
      <div className="container">
        <div className="section-title">
          <h2>
            <b>Rules</b>
          </h2>
          <div className="bar"></div>
        </div>

        <h5>
          This hackathon will follow the open source{" "}
          <a
            href="https://hackcodeofconduct.org"
            target="_blank"
            style={{ color: "#EE6352" }}
            rel="noopener noreferrer"
          >
            <b>Hack code of conduct</b>
          </a>
        </h5>

        <ol >
          <li>
            
              Teams must have a minimum of 4 members, but no more than 10
              members.
            
          </li>
          <li>
           
              Teams should be made up exclusively of registered participants who
              are not organizers, volunteers, judges, sponsors, or in any other
              privileged position at the event.
            
          </li>
          <li>
            
              All team members should be present at the event.
            
          </li>
          <li>
           
              Teams can of course gain advice and support from organizers,
              volunteers, sponsors, and others.
            
          </li>
          <li>
           All work on a project should be done at the hackathon.
          </li>
          <li>
            Teams can use an idea they had before the event.
          </li>
          <li>
         
              Teams can work on ideas that have already been done. Hacks do not
              have to be “innovative”. If a team wants to work on a common idea
              this is allowed and will only be judged on the quality of the
              hack.{" "}
         
          </li>
          <li>
           
              Teams cannot work on a product that they have worked on before.
              Adding new features to existing projects is not allowed.
           
          </li>
          <li>
       
              Teams can use libraries, frameworks, or open-source code in their
              projects. Working on a project before the event and open-sourcing
              it for the sole purpose of using the code during the event is
              against the spirit of the rules and is not allowed.
           
          </li>
          <li>
          
              Teams must stop hacking once the time is up. However, teams are
              allowed to debug and make small fixes to their programs after time
              is up. e.g. If during demoing your hack you find a bug that breaks
              your application and the fix is only a few lines of code, it's
              okay to fix that. Making large changes or adding new features is
              not allowed.
        
          </li>
          <li>
          
              Teams must be registered by 10pm on Friday, July 22, 2022.
              However, individuals may switch teams, and teams may change their
              ideas after that time.
          
          </li>
          <li>
            Projects that violate the Code of Conduct are not allowed.
          </li>
          <li>
          
              Teams can be disqualified from the competition at the organizers'
              discretion. Reasons might include but are not limited to breaking
              the Competition Rules, breaking the Code of Conduct, or other
              unsporting behavior.
            
          </li>
        </ol>
      </div>
    </section>
  );
}

export default Rules;
