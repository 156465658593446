import React from 'react'
import { Link } from 'react-router-dom'

class Footer extends React.Component {
    renderMailToLink = (label, mailTo) => {
        return (
          <Link to="#" onClick={() => window.location.href = `mailto:${mailTo}`}>
              {label}
          </Link>
        )
    }

    render() {
        return (
            <footer className="footer-area">
                <div className="container">
                    <div className='row mb-5'>
                        <div className='col-lg-12'>
                            <h3 className='text-white'>Land Acknowledgement</h3>
                            <p>We acknowledge the traditional territories of the peoples of
                                the Treaty 7 region in Southern Alberta, which includes the Blackfoot First Nation
                                tribes of Siksika, the Piikuni, the Kainai, the Stoney Nakoda First Nations tribes of
                                Chiniki, Bearspaw, and Wesley and the Tsuut’ina First Nation. The city of Calgary is
                                also homeland to the historic Northwest Métis and to Métis Nation of Alberta, Region 3.
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="single-footer-widget">
                                <h3>Venue</h3>
                                <span>
                                    <i className="icofont-calendar"></i> 22-24 July 2022
                                </span>

                                <p className="location">
                                    <i className="icofont-google-map"></i> Platform Innovation Centre,
                                    407 9 Ave SE, Calgary, AB T2G 2K7
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="single-footer-widget">
                                <h3>Contact Us</h3>
                                <ul className="ps-3">
                                    <li><h6 className="text-white">General Inquiries</h6></li>
                                    {this.renderMailToLink(<p>hackathon@yychacks.ca</p>, 'hackathon@yychacks.ca')}
                                    <li><h6 className="text-white mt-1">Media Inquiries</h6></li>
                                    {this.renderMailToLink(<p>media@yychacks.ca</p>, 'media@yychacks.ca')}
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="copyright-area">
                                <div className="logo mb-3">
                                    <Link to="/">
                                        <img src={require("../../assets/images/logo.png")} alt="logo" height='100px'/>
                                    </Link>
                                </div>
                                <p>
                                    Copyright <i className="icofont-copyright"></i> 2022 YYC Dev. All rights reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
            ;
    }
}

export default Footer;
