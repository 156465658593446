import React from 'react'
import LaxDiv from '../Shared/LaxDiv'
import SpeakerItem from './SpeakerComponent'

class Speakers extends React.Component {
    render() {
        return (
            <section className="speakers-area ptb-120" id="speakers">
                <div className="container">
                    <div className="section-title">
                        <h2>Speakers</h2>
                        <div className="bar"></div>
                        <LaxDiv text="Speakers" dataPreset="driftRight" />
                    </div>
                </div>
                <div className="row gap-md-4 m-0 d-flex justify-content-center">
                    <SpeakerItem
                        speakerName="Mayor Jyoti Gondek"
                        speakerTitle="Mayor of Calgary"
                        imageSource={require("../../assets/images/Madame Mayor.jpg")}
                        speakerFacebook="https://www.facebook.com/JyotiGondek"
                        speakerTwitter="https://twitter.com/JyotiGondek?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                        speakerInsta="https://www.instagram.com/jyotigondek/?hl=en"
                    >
                    </SpeakerItem>

                    <SpeakerItem
                        speakerName="Brad Parry"
                        speakerTitle="President & CEO at Calgary Economic Development & Opportunity Calgary Investment Fund"
                        imageSource={require("../../assets/images/brad_parry_portrait.PNG")}
                        speakerTwitter="https://twitter.com/bradparry"
                        speakerLinkedIn="https://www.linkedin.com/in/bradparry/"
                    >
                    </SpeakerItem>

                </div>
            </section>
        );
    }
}

export default Speakers;
