import React, { useReducer } from "react"
import LaxDiv from "../Shared/LaxDiv"
import Slider from "react-slick"
import FsLightbox from "fslightbox-react"
import Gallery from "../../assets/images/hackathon"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../../assets/css/arrows.css"

const TOGGLE_INDEX = 'TOGGLE_INDEX'
const initialState = { visibility: false, galleryIndex: 0 }

const reducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_INDEX:
      return { ...state, visibility: !state.visibility, galleryIndex: action.galleryIndex }
    default:
      return state
  }
}

const settings = {
  slidesToScroll: 4,
  slidesToShow: 5,
  speed: 500,
  arrows: true,
  infinite: false,
};

const PhotoGallery = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <section className="speakers-area ptb-120" id="speakers">
      <div className="container">
        <div className="section-title">
          <h2>Event Sights</h2>
          <div className="bar"></div>
          <LaxDiv text="Event Sights" dataPreset="driftRight" />
        </div>
      </div>
      <div className="container">
        <Slider {...settings}>
          {Gallery.map((image, index) => (
            <img
              key={index}
              alt={`hackathon ${index}`}
              src={image}
              onClick={() => dispatch({ type: TOGGLE_INDEX, galleryIndex: index })}
            />
          ))}
        </Slider>
      </div>
      <FsLightbox
        toggler={state.visibility}
        sources={Gallery}
        sourceIndex={state.galleryIndex}
      />
    </section>
  )
}

export default PhotoGallery
