import React from 'react';
import { Link } from 'react-router-dom';


function SpeakerItem({
                       imageSource,
                       speakerName,
                       speakerTitle,
                       speakerFacebook,
                       speakerTwitter,
                       speakerInsta,
                       speakerLinkedIn
                     }) {

  return (
    <div className="col-lg-3 col-sm-6 p-0">
      <div className="single-speakers">
        {imageSource !== undefined ?
          <img
            src={imageSource}
            alt="speakers"
          />
          :
          <img
            src={require("../../assets/images/placeholder_image.jpeg")}
            alt="speakers"
          />
        }

        {speakerName === undefined ?
          <div className="speakers-content">
            <h3><Link to="#">TBD</Link></h3>
            <span>----</span>
          </div>
          :
          <div className="speakers-content">
            <h3><Link to="#">{speakerName}</Link></h3>
            <span style={{ marginTop: 35 }}>{speakerTitle}</span>
          </div>
        }
        <ul>
          <li>
            {speakerFacebook !== undefined &&
            <a to="#"  rel="noopener noreferrer" target="_blank" className="facebook" href={speakerFacebook}>
              <i className="icofont-facebook"></i>
            </a>
            }
          </li>
          <li>
            {speakerTwitter !== undefined &&
            <a to="#"  rel="noopener noreferrer" target="_blank" className="twitter" href={speakerTwitter}>
              <i className="icofont-twitter"></i>
            </a>
            }
          </li>
          <li>
            {speakerInsta !== undefined &&
            <a to="#"  rel="noopener noreferrer" target="_blank" className="instagram" href={speakerInsta}>
              <i className="icofont-instagram"></i>
            </a>
            }
          </li>
          <li>
            {speakerLinkedIn !== undefined &&
            <a to="#"  rel="noopener noreferrer" target="_blank" className="linkedin" href={speakerLinkedIn}>
              <i className="icofont-linkedin"></i>
            </a>
            }
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SpeakerItem;
