import React from 'react';
import {Link} from 'react-router-dom';

class WhyUs extends React.Component {
    render(){
        return (
            <section className="why-choose-us">
                <div className="row m-0">
                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-headphone-alt-1"></i>
                                        </div>
                                        <h3>Subject Matter Experts</h3>
                                        <p>Meet and speak to business owners downtown to learn about their challenges first-hand.</p>
                                        <Link to="#" className="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-network-tower"></i>
                                        </div>
                                        <h3>Networking</h3>
                                        <p>Meet like-minded, passionate people in your community.</p>
                                        <Link to="#" className="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-users-social"></i>
                                        </div>
                                        <h3>Mentors</h3>
                                        <p>Learn from the experienced mentors with decades of local and international tech experience.</p>
                                        <Link to="#" className="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-wink-smile"></i>
                                        </div>
                                        <h3>Create Something Valuable</h3>
                                        <p>Build a project for a purpose, with meaningful value for the community.</p>

                                        <Link to="#" className="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ul className='slideshow'>
                    <li><span></span></li>
                    <li><span></span></li>
                    <li><span></span></li>
                    <li><span></span></li>
                </ul>
            </section>
        );
    }
}
 
export default WhyUs;