import React from 'react'
import MainBanner from './MainBanner';
import Speakers from './SpeakersMain';
 
class SpeakersPageFormat extends React.Component {
    render(){
        return (
            <React.Fragment>
                <MainBanner />
                <Speakers />
            </React.Fragment>
        );
    }
}
 
export default SpeakersPageFormat;