import React from "react";
import { Link } from 'react-router-dom';

function MentorItem({ speakerName, role, speakerImg, linkedIn = "", bio, company }) {
  return (
    <div className="col-lg-4 col-sm-6">
      <div className="single-speakers-box">
        <div className="speakers-bio">
          <p>
            {bio}
          </p>
        </div>
        <div className="speakers-image">
          <img
            src={speakerImg}
            alt="speaker"
          />
        </div>

        <div className="speakers-content">
          <h3>
            <Link to="#">{speakerName}</Link>
          </h3>
          <span>
            {role}
          </span>
          <span>
            {company}
          </span>
          {linkedIn !== "" ? (<ul className="social">
            <li>
              <a
                rel="noopener noreferrer"
                href={linkedIn}
                target="_blank"
              >
                <i className="icofont-linkedin"></i>
              </a>
            </li>
          </ul>) : ""}

        </div>
      </div>
    </div>
  );
}

export default MentorItem
