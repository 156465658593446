import React from 'react';
import * as Scroll from 'react-scroll';
import {Link} from 'react-scroll'
import logo from '../../assets/images/logo.png';
import {withRouter} from "react-router-dom";

class Navigation extends React.Component {
    state = {
        collapsed: true,
        isOpen: false
    };

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    componentDidMount() {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
                window.history.pushState("", document.title, window.location.pathname);
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    }

    toggleOpen = () => this.setState({isOpen: !this.state.isOpen});

    componentDidUpdate(nextProps) {
        if (this.props.match.path !== nextProps.match.path) {
            console.log('OK')
        }
    }

    onRouteChanged = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    render() {
        const {collapsed} = this.state;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        const scroll = Scroll.animateScroll;

        return (
            <header id="header" className="header-area">
                <div id="navbar" className="elkevent-nav">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <div className="container">
                            <Link className="navbar-brand" to="/">
                                <img src={logo} alt="logo" style={{maxHeight: '100px'}}/>
                            </Link>

                            <button
                                onClick={this.toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item">
                                        <Link
                                            exact="true"
                                            to="#"
                                            className="nav-link"
                                            activeClass='active'
                                            onClick={() => {
                                                scroll.scrollToTop();
                                                this.toggleNavbar()
                                            }}
                                        >
                                            Home
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                          to="speakers"
                                          offset={-50}
                                          className="nav-link"
                                          activeClass='active'
                                          spy={true}
                                          smooth={true}
                                          onClick={this.toggleNavbar}
                                        >
                                            Speakers
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="schedule"
                                            offset={-50}
                                            className="nav-link"
                                            activeClass='active'
                                            spy={true}
                                            smooth={true}
                                            onClick={this.toggleNavbar}
                                        >
                                            Schedule
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                          to="judges"
                                          className="nav-link"
                                          activeClass='active'
                                          spy={true}
                                          smooth={true}
                                          onClick={this.toggleNavbar}
                                        >
                                            Judges
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                          to="mentors"
                                          offset={-50}
                                          className="nav-link"
                                          activeClass='active'
                                          spy={true}
                                          smooth={true}
                                          onClick={this.toggleNavbar}
                                        >
                                            Mentors
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="sponsors"
                                            offset={-50}
                                            className="nav-link"
                                            activeClass='active'
                                            spy={true}
                                            smooth={true}
                                            onClick={this.toggleNavbar}
                                        >
                                            Sponsors
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="rules"
                                            offset={-50}
                                            className="nav-link"
                                            activeClass='active'
                                            spy={true}
                                            smooth={true}
                                            onClick={this.toggleNavbar}
                                        >
                                            Rules
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="Faq"
                                            offset={-850}
                                            className="nav-link"
                                            activeClass='active'
                                            spy={true}
                                            smooth={true}
                                            onClick={this.toggleNavbar}
                                        >
                                            FAQ
                                        </Link>
                                    </li>
                                </ul>

                                <div className="others-option">
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.showpass.com/calgary-hackathon-2022"
                                                className="btn btn-primary"
                                                target="_blank"
                                                rel='noopener noreferrer'
                                                onClick={this.toggleNavbar}
                                            >
                                                Buy Ticket
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}

export default withRouter(Navigation);
