import React from 'react'

const WinnersDetails = () => {

  return (
    <div className="judging-criteria-rules">
      <div className="container section-title">
        <div className="row">
          <div className="col text-center">
            <h3 className="mb-4">First Place</h3>
            <h5>Team: Easy Licence</h5>
            <p className="mb-0">Matthew Gaiser</p>
            <p className="mb-0">Nicolas Hirschfeld</p>
            <p className="mb-0">Nicholas Lor</p>
            <p className="mb-0">Chansuck Lee</p>
          </div>
          <div className="col text-center">
            <h3 className="mb-4">Second Place</h3>
            <h5>Team: YYCHub</h5>
            <p className="mb-0">Dele Oyelese</p>
            <p className="mb-0">Deborah Odoh</p>
            <p className="mb-0">Hanin Geabel</p>
            <p className="mb-0">Stewart Pratt</p>
            <p className="mb-0">Clint Cadio</p>
            <p className="mb-0">Ania Bilik</p>
          </div>
          <div className="col text-center">
            <h3 className="mb-4">Third Place</h3>
            <h5>Team: YYSecret</h5>
            <p className="mb-0">Mitchell Miller</p>
            <p className="mb-0">Nurgul Akhshatayeva</p>
            <p className="mb-0">Binh Kieu (Bruce)</p>
            <p className="mb-0">Kushan Wickramarachchi</p>
            <p className="mb-0">Tim Lipp</p>
            <p className="mb-0">Taylor Morgan</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WinnersDetails
