import React from "react";

function ResourcesItem({resourceNames, resourceLinks, resourceTitle}) {
  return (
    <div>
      <h5>{resourceTitle}</h5>
      <ul>
        {resourceNames.map((name, i)=>  (<li>
          <a
            href={resourceLinks[i]}
            target="_blank"
            style={{ color: "#EE6352" }}
            rel="noopener noreferrer"
          >
            {name}
          </a>
        </li>))}
       
      </ul>
    </div>
  );
}

export default ResourcesItem