import React from "react"
import LaxDiv from '../Shared/LaxDiv'
import PressItem from './PressItem'

const Press = () => {
  return (
    <section className="speakers-area ptb-120" id="speakers">
      <div className="container">
        <div className="section-title">
          <h2>In The News</h2>
          <div className="bar"></div>
          <LaxDiv text="In The News" dataPreset="driftRight" />
        </div>
      </div>
      <div className="row gap-md-4 m-0 d-flex justify-content-center">
        <PressItem
          href={"https://calgaryherald.com/news/local-news/hackathon-set-to-take-on-challenges-of-rejuvenating-calgarys-downtown"}
          imageSource={require("../../assets/images/CH-PRESS.png")}
        />
        <PressItem
          href={"https://livewirecalgary.com/2022/07/19/city-hackathon-geared-towards-calgarys-downtown/"}
          imageSource={require("../../assets/images/LWC-PRESS1.png")}
        />
        <PressItem
          href={"https://livewirecalgary.com/2022/07/25/hackathon-highlights-changing-perceptions-of-tech-industry-in-calgary/"}
          imageSource={require("../../assets/images/LWC-PRESS2.png")}
        />
      </div>
    </section>
  )
}

export default Press
