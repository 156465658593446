import React from "react";
import PrizeItem from "./PrizeComponent";

function Prizes() {
  return (
    <section className="prizes-area ptb-120">
      <div className="container">
        <div className="section-title">
          <h2>
            <b>Prizes</b>
          </h2>
        </div>
        <PrizeItem place="First" amountWon={"$3000"}></PrizeItem>
        <PrizeItem place="Second" amountWon={"$2000"}></PrizeItem>
        <PrizeItem place="Third" amountWon={"$1000"}></PrizeItem>
      </div>
    </section>
  );
}

export default Prizes;
