import React from "react";
import RescourceItem from "./ResourcesItem";

function Resources() {
  return (
    <section className="rules-area ptb-120" id="rules">
      <div className="container">
        <div className="section-title">
          <h2>
            <b>Resources</b>
          </h2>
          <div className="bar"></div>
        </div>
        <RescourceItem
          resourceNames={[
            "List of Datasets",
            "Calgary Transit Data",
            "API Docs",
          ]}
          resourceLinks={[
            "https://data.calgary.ca/browse",
            " https://data.calgary.ca/browse?tags=lrt&utf8=%E2%9C%93",
            "https://dev.socrata.com/docs/endpoints.html",
          ]}
          resourceTitle="Open Calgary"
        />
        <RescourceItem
          resourceNames={["Databases"]}
          resourceLinks={["https://www.statcan.gc.ca/en/lode/databases"]}
          resourceTitle="Statistics Canada Open Databases"
        />
        <RescourceItem
          resourceNames={["Datasets", "List of Datasets for Calgary"]}
          resourceLinks={[
            "https://open.alberta.ca/dataset",
            " https://open.alberta.ca/dataset?q=%22Calgary%22",
          ]}
          resourceTitle="Open Alberta Datasets"
        />
        <RescourceItem
          resourceNames={[
            "List of Datasets for Calgary",
            "Datasets in Alberta",
          ]}
          resourceLinks={[
            "https://search.open.canada.ca/en/od/?od-search-portal=Open+Data&search_text=calgary",
            "https://open.canada.ca/data/en/organization/ab?page=5",
          ]}
          resourceTitle="Open Canada Datasets"
        />
        <RescourceItem
          resourceNames={["Data"]}
          resourceLinks={["https://www.opendataareas.ca/#data"]}
          resourceTitle="Open Data Areas Alberta"
        />
        <RescourceItem
          resourceNames={["APIs"]}
          resourceLinks={[
            "https://www.library.ualberta.ca/about-us/open-data/api",
          ]}
          resourceTitle="University of Alberta Data APIs"
        />
      </div>
    </section>
  );
}

export default Resources;
