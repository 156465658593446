import { useEffect, useRef, useState } from "react"

const useMouseHover = () => {
  const [value, setValue] = useState(false)
  const ref = useRef(null)

  const onMouseOver = () => setValue(true)
  const onMouseOut = () => setValue(false)

  useEffect(() => {
    const node = ref.current

    if (node) {
      node.addEventListener('mouseover', onMouseOver)
      node.addEventListener('mouseout', onMouseOut)
    }

    return () => {
      if (node) {
        node.removeEventListener('mouseover', onMouseOver)
        node.removeEventListener('mouseout', onMouseOut)
      }
    }
  }, [])

  return [ref, value]
}

export default useMouseHover
