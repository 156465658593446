import React from "react";
import lax from "lax.js";
import Sponsors from "../Common/Sponsors";
import SponsorItem from "./SponsorItem";

import PixelTree from "../../assets/images/pixeltree-logo.png";
import PlatformCalgary from "../../assets/images/platform-logo.png";
import GoodLawyer from "../../assets/images/goodlawyer.png";
import CED from "../../assets/images/ced-dev-logo-lg.png";
import YYCData from "../../assets/images/yyc-data-logo.svg";
import ITIQ from "../../assets/images/it-iq-logo.jpg";
import Arcurve from "../../assets/images/arcurve-logo.jpeg";
import Helcim from "../../assets/images/helcim.png";
import VOGLP from "../../assets/images/vog-lp-logo.png";
import VOG from "../../assets/images/vog-logo.png";
import CityOfCalgary from "../../assets/images/calgs-city-logo.png";
import CCImaging from "../../assets/images/cc-imging-logo.png";
import RainforestAB from "../../assets/images/rainforest.jpeg";
import ChicGeek from "../../assets/images/chicgeek.png";
import StartupTNT from "../../assets/images/startup-tnt-logo.png";
import Careers from "../../assets/images/careers-logo.png";
import Harvest from "../../assets/images/harvest-logo.png";
import Sait from "../../assets/images/educational-sponsor-sait.png";
import BowValley from "../../assets/images/educational-sponsor-bow-valley-college.png";
import VantageCircle from "../../assets/images/vantage-circle-logo.png";
import ManPower from "../../assets/images/manpower-logo.png";
import Retrain from "../../assets/images/retrain-canada-logo.png";
import Aiot from "../../assets/images/aiota-logo.jpeg";

function Partner() {
  lax.setup();

  document.addEventListener(
    "scroll",
    function (x) {
      lax.update(window.scrollY);
    },
    false
  );

  lax.update(window.scrollY);

  return (
    <section className="partner-area ptb-120" id="sponsors">
      <div className="container">
        <div className="section-title">
          <span>The Partners Who Make This Event Possible!</span>
          <h2>
            Our Event <b>Sponsors</b>
          </h2>

          <div className="bar"></div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="partner-title platinum-sponsor">
              <Sponsors sponsorTitle="Platinum Sponsors" />
            </div>
          </div>

          <div className="row gap-2 justify-content-center align-items-center d-flex">
            <SponsorItem
              imageSrc={PixelTree}
              imageName="Pixeltree"
              siteHref="https://pixeltree.ca"
            />
            <SponsorItem
              imageSrc={PlatformCalgary}
              imageName="Platform Calgary"
              siteHref="https://platformcalgary.com"
            />
          </div>

          <div className="col-lg-12">
            <div className="border"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="partner-title platinum-sponsor">
              <Sponsors sponsorTitle="Gold Sponsors" />
            </div>
          </div>

          <div className="row justify-content-center align-items-center gap-2">
            <SponsorItem
              imageSrc={VantageCircle}
              imageName="Vantage Circle"
              siteHref="https://www.vantagecircle.com/"
            />
            <SponsorItem
              imageSrc={Retrain}
              imageName="ReTrain Canada"
              siteHref="https://retraincanada.com/"
            />

            <SponsorItem
              imageSrc={ManPower}
              imageName="ManPower"
              siteHref="https://manpowerab.com/"
            />
          </div>

          <div className="col-lg-12">
            <div className="border"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="partner-title platinum-sponsor">
              <Sponsors sponsorTitle="Silver Sponsors" />
            </div>
          </div>

          <div className="row justify-content-center align-items-center gap-2">
            <SponsorItem
              imageSrc={GoodLawyer}
              imageName="Good Lawyer"
              siteHref="https://www.goodlawyer.ca/"
            />
          </div>

          <div className="col-lg-12">
            <div className="border"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="partner-title educational-sponsor">
              <Sponsors sponsorTitle="Educational Sponsors" />
            </div>
          </div>

          <div className="row justify-content-center align-items-center gap-2">
            <SponsorItem
              imageSrc={Sait}
              imageName="Sait"
              siteHref="https://sait.ca"
            />

            <SponsorItem
              imageSrc={BowValley}
              imageName="Bow Valley College"
              siteHref="https://www.bowvalleycollege.ca/"
            />
          </div>

          <div className="col-lg-12">
            <div className="border"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="partner-title platinum-sponsor">
              <Sponsors sponsorTitle="Ambassadors" />
            </div>
          </div>

          <div className="row justify-content-center align-items-center gap-2">
            <SponsorItem
              imageName="Calgary Economic Development"
              imageSrc={CED}
              siteHref="https://www.calgaryeconomicdevelopment.com"
              classname="col-md-2"
            />
            <SponsorItem
              imageName="Alberta IoT"
              imageSrc={Aiot}
              siteHref="https://albertaiot.com/"
              classname="col-md-2"
            />
            <SponsorItem
              imageName="YCC Data"
              imageSrc={YYCData}
              siteHref="https://www.yycdata.ca"
              classname="col-md-2"
            />
            <SponsorItem
              imageName="IT IQ"
              imageSrc={ITIQ}
              siteHref="https://www.it-iq.com/tech"
              classname="col-md-2"
            />
            <SponsorItem
              imageName="Arcurve"
              imageSrc={Arcurve}
              siteHref="https://www.arcurve.com"
              classname="col-md-2"
            />
            <SponsorItem
              imageName="Helcim"
              imageSrc={Helcim}
              siteHref="https://www.helcim.com/"
              classname="col-md-2"
            />
            <SponsorItem
              imageName="VOG Launchpad"
              imageSrc={VOGLP}
              siteHref="https://launchpadbyvog.com/"
              classname="col-md-2"
            />
            <SponsorItem
              imageName="VOG"
              imageSrc={VOG}
              siteHref="https://vogcalgaryappdeveloper.com/"
              classname="col-md-2"
            />
            <SponsorItem
              imageName="City of Calgary"
              imageSrc={CityOfCalgary}
              siteHref="https://www.calgary.ca/"
              classname="col-md-2"
            />
            <SponsorItem
              imageName="Circle Cardiovascular Imaging"
              imageSrc={CCImaging}
              siteHref="https://www.circlecvi.com/"
              classname="col-md-2"
            />
          </div>

          <div className="col-lg-12">
            <div className="border"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="partner-title platinum-sponsor">
              <Sponsors sponsorTitle="Community Partners" />
            </div>
          </div>

          <div className="row justify-content-center align-items-center gap-2">
            <SponsorItem
              imageName="Rainforest Alberta"
              imageSrc={RainforestAB}
              siteHref="https://www.rainforestab.ca/"
              classname="col-md-2"
            />
            <SponsorItem
              imageName="Chic Geek"
              imageSrc={ChicGeek}
              siteHref="https://thechicgeek.ca"
              classname="col-md-2"
            />
            <SponsorItem
              imageName="Startup TNT"
              imageSrc={StartupTNT}
              siteHref="https://www.startuptnt.com"
              classname="col-md-2"
            />
            <SponsorItem
              imageName="Careers"
              imageSrc={Careers}
              siteHref="https://www.careersnextgen.ca"
              classname="col-md-2"
            />
            <SponsorItem
              imageName="Harvest"
              imageSrc={Harvest}
              siteHref="https://www.harvest.builders/"
              classname="col-md-2"
            />
          </div>

          <div className="col-lg-12">
            <div className="border"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partner;
