import React from 'react';
import ModalVideo from 'react-modal-video';
import CountdownTimer from './Countdown';

class MainBanner extends React.Component {

    state = {
        days: '',
        hours: '',
        minutes: '',
        seconds: '',
        isOpen: false
    };

    openModal = () => {
        this.setState({ isOpen: true })
    }

    render() {
        const current = new Date();
        const endDate = "August 11 2022 00:10:13 MDT"
        return (
            <React.Fragment>
                <ModalVideo
                    channel='youtube'
                    isOpen={this.state.isOpen}
                    videoId='cRXm1p-CNyk'
                    onClose={() => this.setState({ isOpen: false })}
                />

                <div className="main-banner item-bg1">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="main-banner-content">
                                    <p>Are you <span>ready</span> to hack?</p>
                                    <h1>Calgary's <span>Biggest</span> <br /> Hackathon <b>2</b><b>0</b><b>2</b><b>2</b></h1>

                                    <ul>
                                        <li><i className="icofont-compass"></i> Calgary, Alberta</li>
                                        <li><i className="icofont-calendar"></i> 22-24 July, 2022</li>
                                    </ul>

                                    <div className="button-box">
                                        <a href="https://www.showpass.com/calgary-hackathon-2022"
                                            className="btn btn-primary"
                                            target="_blank"
                                            rel='noopener noreferrer'>Get Your Ticket!</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {current.getTime() < new Date(endDate).getTime() &&
                        <CountdownTimer
                            endTimeValue={endDate}
                        />
                    }

                    <div className="shape1">
                        <img src={require("../../assets/images/shapes/1.png")} alt="shape1" />
                    </div>

                    <div className="shape2 rotateme">
                        <img src={require("../../assets/images/shapes/2.png")} alt="shape2" />
                    </div>

                    <div className="shape3 rotateme">
                        <img src={require("../../assets/images/shapes/3.png")} alt="shape3" />
                    </div>

                    <div className="shape4">
                        <img src={require("../../assets/images/shapes/4.png")} alt="shape4" />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default MainBanner;