import React from 'react';
import lax from 'lax.js';
import { Link } from 'react-router-dom';
import LaxDiv from '../Shared/LaxDiv';
 
class Judges extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    render(){
        return (
            <section className="speakers-area ptb-120 pb-0">
                <div className="container">
                    <div className="section-title">
                        <h2>Judges</h2>
                        <div className="bar"></div>
                        <LaxDiv text="Judges" dataPreset="driftRight" />
                    </div>
                </div>

                <div className="row m-0">
                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-speakers">
                            <img style={{width: '700px', height: '500px'}} src={require("../../assets/images/ian_judge_headshot.jpeg")} alt="Judge" />

                            <div className="speakers-content">
                                <h3><Link to="#">Ian Sutherland</Link></h3>
                                <span>Principle Software Engineer</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-speakers">
                            <img style={{width: '700px', height: '500px'}} src={require("../../assets/images/kellie_judges_headshot.jpeg")} alt="Judge" />

                            <div className="speakers-content">
                                <h3><Link to="#">Kellie Knight</Link></h3>
                                <span>Production Manager</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-speakers">
                            <img style={{width: '700px', height: '500px'}} src={require("../../assets/images/placeholder_image.jpeg")} alt="Judge" />

                            <div className="speakers-content">
                                <h3><Link to="#">Mark Gardner</Link></h3>
                                <span>----</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Judges;
