import image1 from './IMG_0307_Reduced.jpg'
import image2 from './IMG_0315_Reduced.jpg'
import image3 from './IMG_0331_Reduced.jpg'
import image4 from './IMG_0370_Reduced.jpg'
import image5 from './IMG_0373_Reduced.jpg'
import image6 from './IMG_0374_Reduced.jpg'
import image7 from './IMG_0381_Reduced.jpg'
import image8 from './IMG_0389_Reduced.jpg'
import image9 from './IMG_0402_Reduced.jpg'
import image10 from './IMG_0408_Reduced.jpg'
import image11 from './IMG_0415_Reduced.jpg'
import image12 from './IMG_0436_Reduced.jpg'
import image13 from './IMG_0446_Reduced.jpg'
import image14 from './IMG_0451_Reduced.jpg'
import image15 from './IMG_0467_Reduced.jpg'
import image16 from './IMG_0509_Reduced.jpg'
import image17 from './IMG_0519_Reduced.jpg'
import image18 from './IMG_0543_Reduced.jpg'
import image19 from './IMG_0569_Reduced.jpg'
import image20 from './IMG_0572_Reduced.jpg'
import image21 from './IMG_0578_Reduced.jpg'
import image22 from './IMG_0595_Reduced.jpg'
import image23 from './IMG_0616_Reduced.jpg'
import image24 from './IMG_0641_Reduced.jpg'
import image25 from './IMG_0682_Reduced.jpg'
import image26 from './IMG_0691_Reduced.jpg'
import image27 from './IMG_0698_Reduced.jpg'
import image28 from './IMG_0709_Reduced.jpg'
import image29 from './IMG_0718_Reduced.jpg'
import image30 from './IMG_0731_Reduced.jpg'
import image31 from './IMG_0755_Reduced.jpg'
import image32 from './IMG_0783_Reduced.jpg'
import image33 from './IMG_0802_Reduced.jpg'
import image34 from './IMG_0804_Reduced.jpg'
import image35 from './IMG_0816_Reduced.jpg'
import image36 from './IMG_0829_Reduced.jpg'

export default [
  image1, image2, image3, image4, image5, image6, image7, image8, image9, image10,
  image11, image12, image13, image14, image15, image16, image17, image18, image19, image20,
  image21, image22, image23, image24, image25, image26, image27, image28, image29, image30,
  image31, image32, image33, image34, image35, image36,
]
