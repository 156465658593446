import React from 'react'
import useMouseHover from "../../hooks/useMouseHover"

const PressItem = ({ imageSource, href = null }) => {
  const [hoverRef, isHovered] = useMouseHover()
  const hoverStyle = isHovered ? { transition: 'transform 0.5s ease', transform: 'scale(1.1)' } : null

  return (
    <div className="col-lg-3 col-sm-6 p-0 card shadow-sm overflow-hidden">
      <a
        className=""
        style={hoverStyle}
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        ref={hoverRef}
      >
        {imageSource !== undefined ?
          <img
            src={imageSource}
            alt="speakers"
          />
          :
          <img
            src={require("../../assets/images/placeholder_image.jpeg")}
            alt="speakers"
          />
        }
      </a>
    </div>
  )
}

export default PressItem
