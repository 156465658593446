import React from 'react';
import MainBanner from '../HomeDefault/MainBanner';
import About from '../HomeDefault/About';
import WhyUs from '../Common/WhyUs';
import GoTop from '../Shared/GoTop';
import Footer from '../Common/Footer';
import EventSchedules from '../HomeDefault/EventSchedules';
import lax from 'lax.js';
import Partner from '../Common/Partner';
import BuyTicket from '../Common/BuyTicket';
import Prizes from '../Common/Prizes'
import Judges from "../HomeThree/Judges";
import FAQ from './Faq.jsx';
import Rules from "../Common/Rules";
import Vignettes from './VignettesComponent';
import Resources from '../Common/Resources';

import Mentors from "../HomeThree/Mentors";
import Speakers from '../Speakers/SpeakersMain';
import Press from "../Press"
import Winners from "../Winners"
import PhotoGallery from "../PhotoGallery"

class HomeDefault extends React.Component {
  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener('scroll', function(x) {
      lax.update(window.scrollY)
    }, false)

    lax.update(window.scrollY)
  }
  render() {
    return (
      <React.Fragment>
        <MainBanner />
        <Press />
        <Winners />
        <PhotoGallery />
        <About />
        <WhyUs />
        <Speakers />
        <EventSchedules />
        <Judges/>
        <Mentors/>
        <Prizes />
        <Rules />
        <Vignettes />
        <Resources />
        <BuyTicket />
        <Partner />
        <FAQ />
        <Footer />
        <GoTop scrollStepInPx="50" delayInMs="16.66" />
      </React.Fragment>
    );
  }
}

export default HomeDefault;
