import React from "react"

function VignettePlayer({ vignetteDescription, vignette, vignettePoster, vignetteSpeaker }) {
    return (
        <div className="col-sm-4">
            <video
                width="360"
                height="210"
                controls
                poster={vignettePoster}
                src={vignette}
            />

            <div >
                <div className="section-title d-flex justify-content-left">
                    <h6>
                        <b>{vignetteSpeaker}</b> 
                        <span>{vignetteDescription}</span>
                    </h6>
                </div>
            </div>
        </div>

    );
}

export default VignettePlayer;