import React from "react";
import lax from "lax.js";
import LaxDiv from "../Shared/LaxDiv";
import MentorItem from "../Common/MentorItem";
import DanielHuss from "../../assets/images/daniel_mentor_edited.jpg";
import RyanCorry from "../../assets/images/ryan_corry_mentor.jpg";
import JoeW from "../../assets/images/JoeWilson.PNG";
import LeeA from "../../assets/images/Lee-Ackerman.jpg";
import Micky from "../../assets/images/micky-mentor.png";

function Mentors() {
  lax.setup();

  document.addEventListener(
    "scroll",
    function (x) {
      lax.update(window.scrollY);
    },
    false
  );

  lax.update(window.scrollY);

  return (
    <section id="mentors" className="Mentors-area-two ptb-120">
      <div className="container">
        <div className="section-title">
          <h2>Mentors</h2>
          <div className="bar"></div>
          <LaxDiv text="Mentors" dataPreset="driftRight" />
        </div>

        <div className="row">
          <MentorItem
            speakerName="Daniel Huss"
            speakerImg={DanielHuss}
            role="Tech Mentor"
            bio="Daniel is a software developer who leans on Test Driven 
            Development to help build clear and communicative code, primarily 
            in Ruby and JavaScript. He's very excited to spend the weekend with 
            Calgary devs, and see our creative and technical talent on display.
            "
          />
          <MentorItem
            speakerName="Ryan Corry"
            speakerImg={RyanCorry}
            role="Entrepreneurship Mentor"
            bio="eCommerce entrepreneur, experience with Shopify, Ruby on Rails
             + React builds, UI/UX and branding strategy. Marketing background 
             with Facebook AdsManager, social media management, event production 
             & vending, Mailchimp, HooteSuite. Experience in raising capital, 
             offshore dev, onshore dev, data rooms, pitch decks. Please take a 
             look at my LinkedIn."
          />
          <MentorItem
            speakerName="Joe Wilson"
            speakerImg={JoeW}
            role="Tech Mentor"
            bio="Joe is a part-time web developer, part-time computer science teacher, 
            part-time tinkerer, and a full time dad. His main focus as a developer has 
            been in frontend development (primarily using VueJS) and serverless or server-lite 
            architectures to get projects up and running quickly and inexpensively but he's also 
            always excited to work on projects using computing in unexpected places such special '
            effects equipment for film and TV."
          />
          <MentorItem
            speakerName="Lee Ackerman"
            speakerImg={LeeA}
            role="Tech Mentor"
            bio= "A software engineer at heart, Lee always seeks opportunities to grow, 
            stretch, learn, and share. Lee passionately challenges 'business as usual' 
            and seeks to reimagine and create. Talk to Lee about: 
            co-creation and experimenting at scale, Web3 and the future of learning and work 
            ...Anything tech-related, really. Lee’s experience with startups, some of the 
            worlds largest enterprises, consulting, innovating, and inventing speak to his 
            curiosity and passion for impact."
          />
          <MentorItem
            speakerName="Micky Multani"
            speakerImg={Micky}
            role="Tech Mentor"
            bio="Micky is the co-founder and CEO of Lumin, where he leads 
            the develolment of utility-based Web 3.0 products; starting with 
            PoutineSwap - Canada’s true zero-fee crypto exchange and Jellyfish 
            - crypto payments rails, POS and institutional layer 2 protocols."
          />

          <div className="col-lg-4 col-sm-6"></div>
        </div>
      </div>
    </section>
  );
}

export default Mentors;
