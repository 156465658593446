import React from 'react';

class BuyTicket extends React.Component {
    render() {
        return (
            <section className="buy-tickets-area ptb-120">
                <div className="buy-tickets">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <span>Don't miss it!</span>
                                    <h2>Reserve your spot</h2>

                                    <p>Due to the size of the facility, only a limited number of tickets are
                                        available.</p>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="buy-ticket-btn">
                                    <a href="https://www.showpass.com/calgary-hackathon-2022"
                                       target="_blank"
                                       rel='noopener noreferrer'
                                       className="btn btn-primary">Buy Ticket!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default BuyTicket;