import React from "react";

function PrizeItem({ place, amountWon }) {
    return (
        <ul>
            <li>
                <div className="section-title">
                    <h2 style={{ fontSize: 25 }}>
                        {place} place winners will receive
                        <b> {amountWon} </b>
                        and a <b> GoodLawyer pro subscrption</b>!
                    </h2>
                    <div className="bar"></div>
                </div>
            </li>
        </ul>
    );
}

export default PrizeItem;
