import React from "react";
import VignettesItem from "./VignettesFormat";

function Vignettes() {
    return (
        <div className="container">
            <div className="section-title">
                <span>What are you hacking for?</span>
                <h2>
                    <b>Vignettes</b>
                </h2>
                <div className="bar"></div>
            </div>
            <div className="row d-flex justify-content-center">
                <VignettesItem
                    vignette={require("../../assets/video/Business-Cody-Hendry.m4v")}
                    vignetteSpeaker="Cody Hendry"
                    vignetteDescription="- Calgary Downtown Association">
                </VignettesItem>
                <VignettesItem
                    vignette={require("../../assets/video/Digital-Service-Aliya-Abdallah.m4v")}
                    vignetteSpeaker="Aliya Abdallah"
                    vignetteDescription="- Digital Service Squad">
                </VignettesItem>
                <VignettesItem
                    vignette={require("../../assets/video/Green-Line-Juliet-Pitts.m4v")} 
                    vignetteSpeaker="Juliet Pitts"
                    vignetteDescription="- Green Line LRT">
                </VignettesItem>
                <VignettesItem
                    vignette={require("../../assets/video/Security-Kathleen-Maddan.m4v")}
                    vignetteSpeaker="Kathleen Maddan"
                    vignetteDescription="- Calgary Community Standards - City of Calgary">
                </VignettesItem>
                <VignettesItem
                    vignette={require("../../assets/video/Inclusion-Jeny-Mathews-Thusoo.m4v")}
                    vignetteSpeaker="Jeny Mathews Thusoo"
                    vignetteDescription={"- Program Lead, Resilience - City of Calgary"}>
                </VignettesItem>
            </div>
        </div>
    );
}

export default Vignettes;