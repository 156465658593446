import React from "react";
import lax from "lax.js";
import LaxDiv from "../Shared/LaxDiv";
import MentorItem from "../Common/MentorItem";
import Ian from "../../assets/images/ian_judge_headshot.jpeg";
import Kellie from "../../assets/images/kellie_judges_headshot.jpeg";
import Jasmine from "../../assets/images/jasmine-judge.jpeg";

function Judges() {
  lax.setup();

  document.addEventListener(
    "scroll",
    function (x) {
      lax.update(window.scrollY);
    },
    false
  );

  lax.update(window.scrollY);

  return (
    <section id="judges" className="speakers-area-two ptb-120">
      <div className="container">
        <div className="section-title">
          <h2>Judges</h2>
          <div className="bar"></div>
          <LaxDiv text="Judges" dataPreset="driftRight" />
        </div>

        <div className="row">
          <MentorItem
            speakerImg={Ian}
            speakerName="Ian Sutherland"
            role="Principle Software Engineer"
            linkedIn="https://www.linkedin.com/in/iansu/"
            company="Neo Financial"
          />
          <MentorItem
            speakerImg={Kellie}
            speakerName="Kellie Knight"
            role="Production Manager"
            linkedIn="https://www.linkedin.com/in/kelliedknight"
            company="Arts Commons"
          />
          <MentorItem
            speakerImg={Jasmine}
            speakerName="Jasmine Palardy"
            role="Principle Catalyst"
            linkedIn="https://www.linkedin.com/in/jasminepalardy"
            company="The Good Future Co."
          />


          <div className="judging-criteria-rules">
            <div className="container section-title">
              <h2>
                <b>Scoring</b>
              </h2>
              <div className="bar" />
              <br />
              <ul>
                <li>
                  <h6>Design & Implementation (maximum 50 points)</h6>
                  <p>
                    Does it work? Is it implementable? Did the team think about
                    the user interface (UI) and user experience (UX)? Was the
                    product designed with humans in mind?
                  </p>
                </li>

                <li>
                  <h6>Innovation (maximum 25 points)</h6>
                  <p>
                    How innovative / creative / unique is the idea? Was there a
                    novel approach applied to solve the problem?
                  </p>
                </li>

                <li>
                  <h6>Impact (maximum 25 points)</h6>
                  <p>
                    How impactful is the idea? Can it impact the lives of many
                    people in a significant way? Is the idea aligned with and
                    addresses one or more of the challenge problems?
                  </p>
                </li>

                <li>
                  <h6>Viability (maximum 25 points)</h6>
                  <p>
                    Is this product realistic? How original is the concept Has
                    the team considered route to delivery and has made proactive
                    actions to execute an action plan? Is the idea feasible,
                    does it have economic and societal value, and is it
                    sustainable?
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Judges;
