import React from 'react';
import ReactDOM from 'react-dom';
import lax from 'lax.js';

class Sponsors extends React.Component {
  constructor( props ) {
    super(props);
    this.state = { title: props.sponsorTitle };
  }

  componentDidMount() {
    this.el = ReactDOM.findDOMNode(this)
    lax.addElement(this.el)
  }

  componentWillUnmount() {
    lax.removeElement(this.el)
  }

  render(){
    return (
      <h3 className="lax" data-lax-preset="driftLeft">{this.state.title}</h3>
    );
  }
}

export default Sponsors;
