import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import LaxDiv from '../Shared/LaxDiv';
import AccordionItem from './Accordion/AccordionItem';

class EventSchedules extends React.Component {

  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener('scroll', function (x) {
      lax.update(window.scrollY)
    }, false)

    lax.update(window.scrollY)
  }

  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    evt.currentTarget.className += "current";
  }

  EventItem = ({ eventTitle, time, speaker, content, img }) => {
    return (
      <AccordionItem
        title={
          <Link className="accordion-title" to="#">
            <div className="schedule-info">
              <h3>{eventTitle}</h3>
              <ul>
                <li> {speaker && (
                  <div>
                    <i className="icofont-user-suited"></i>
                    By {speaker}
                  </div>)}</li>
                <li><i className="icofont-wall-clock"></i> {time}</li>
                {img &&
                <div className="author">
                  <img
                    src={require(`../../assets/images/${img}`)}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Steven Smith"
                    alt="Author"
                  />
                </div>
                }
              </ul>
            </div>
          </Link>
        }
        content={
          <div className="accordion-content">
            <p>{content}</p>
          </div>
        }
      />
    );
  }

  render() {
    return (
      <section className="schedule-area bg-image ptb-120" id='schedule'>
        <div className="container">
          <div className="section-title">
            <span>Schedule</span>
            <h2>Tentative <b>Event</b> Schedule</h2>

            <LaxDiv text="Events" dataPreset="driftLeft" />

            <a href="https://www.showpass.com/calgary-hackathon-2022"
               target="_blank"
               rel='noopener noreferrer' className="btn btn-primary">Buy Tickets Now!</a>

            <div className="bar"></div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="tab">
                <ul className="tabs active">
                  <li
                    onClick={(e) => this.openTabSection(e, 'day1tab')}
                    className="current"
                  >
                    <Link to="#">
                      First Day
                      <span>22 July 2022</span>
                    </Link>
                  </li>

                  <li onClick={(e) => this.openTabSection(e, 'day2tab')}>
                    <Link to="#">
                      Second Day
                      <span>23 July 2022</span>
                    </Link>
                  </li>

                  <li onClick={(e) => this.openTabSection(e, 'day3tab')}>
                    <Link to="#">
                      Third Day
                      <span>24 July 2022</span>
                    </Link>
                  </li>
                </ul>

                <div className="tab_content">
                  <div id="day1tab" className="tabs_item">
                    <ul className="accordion">
                      <this.EventItem eventTitle="Doors Open and Registration Begins" iconClass="icofont-wall-clock"
                                      time="3:30PM - 5:00PM"
                                      content={'Doors open at 3:30pm and registration will begin. This is the time to network and meet people.  We will have plenty of snacks and pop for you to enjoy before getting started. '} />
                      <this.EventItem eventTitle="Mayor Keynote Opening" iconClass="icofont-wall-clock"
                                      time="5:00PM - 5:30PM" speaker=' Mayor Jyoti Gondek' img='keynote-mayor.jpg'
                                      content={'Our Mayor Keynote Opening starts at 5pm. All attendees will be informed of the rules and judging criteria. We will have ice breakers and challenge problems (vignettes) to ensure everyone is on the same page. '} />
                      <this.EventItem eventTitle="Team Formation" iconClass="icofont-wall-clock"
                                      time="6:00PM - 10:00PM"
                                      content={'Teams will be formed at 6pm where groups can make their bases and get to work.'} />
                      <this.EventItem eventTitle="End of Day One" iconClass="icofont-wall-clock" time="10:00PM"
                                      content={'Doors close at 10pm.'} />
                    </ul>
                  </div>

                  <div id="day2tab" className="tabs_item">
                    <ul className="accordion">
                      <this.EventItem eventTitle="Doors Open" iconClass="icofont-wall-clock" time="7:00AM"
                                      content={'Doors will be open at 7am. Breakfast will not be provided so please eat beforehand. Open work time can begin'} />
                      <this.EventItem eventTitle="Open Work Time" iconClass="icofont-wall-clock"
                                      time="7:00AM - 12:00PM"
                                      content={'If you have any questions our volunteers will be hanging around in the area. Don’t forget to check out and sign up for mentor time slots. '} />
                      <this.EventItem eventTitle="Lunch" iconClass="icofont-wall-clock" time="12:00PM - 1:00PM"
                                      content={'Lunch will be provided at 12pm for all attendees. This is a great time to network with others or bring food back to continue working if need be'} />
                      <this.EventItem eventTitle="Mentor Sessions" iconClass="icofont-wall-clock"
                                      time="1:00PM - 6:00PM" img='daniel-huss.jpg'
                                      content={'Mentor sessions run from 1pm-6pm. Dinner will be served after the sessions at 6pm and will be for all attendees. '} />
                      <this.EventItem eventTitle="Dinner" iconClass="icofont-wall-clock" time="6:00PM" />
                      <this.EventItem eventTitle="End of Day Two" iconClass="icofont-wall-clock" time="10:00PM"
                                      content={'Doors close at 10pm.'} />
                    </ul>
                  </div>

                  <div id="day3tab" className="tabs_item">
                    <ul className="accordion">
                      <this.EventItem eventTitle="Doors Open" iconClass="icofont-wall-clock" time="7:00AM"
                                      content={'The doors open at 7am and you will have open work time to finish your projects. Again, breakfast will not be provided.'} />
                      <this.EventItem eventTitle="Open Work Time" iconClass="icofont-wall-clock"
                                      time="7:00AM - 12:00PM"
                                      content={'All coding stops at 12pm and lunch will be served'} />
                      <this.EventItem eventTitle="Judge Deliberation" iconClass="icofont-wall-clock"
                                      time="12:00PM - 2:30PM"
                                      content={'Judge deliberation will run from 12pm-2:30pm where you can meet the judges as they look at your projects.'} />
                      <this.EventItem eventTitle="Closing Remarks" iconClass="icofont-wall-clock"
                                      time="2:30PM" speaker='Brad Parry' img='brad-parry.png'
                                      content={'Closing remarks by Brad Parry will start at 2:30'} />
                      <this.EventItem eventTitle="Results Announced" iconClass="icofont-wall-clock" time="3:00PM"
                                      content={'The final results will be announced at 3pm where Serene Yew will announce the winners. Prizes are TBA.'} />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default EventSchedules;
